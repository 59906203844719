<template>
    <div class="header ticker">
        <labelc type="ticker" v-for="(label, indexT) in textArray" :key="label" :text="label"/>
    </div>
</template>

<script>
    export default {
        name: "tickertext",
        data(){
            return {
                textArray: [],
                scrollSpeed: 50,
                timeOutWatch: null,
                stopTick: false,
            }
        },
        watch: {
            state: {
              immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                    if(this.timeOutWatch) clearTimeout(this.timeOutWatch);
                    this.ScrollFrame();
                },
            }
        },
        props:{
            state: Object,
        },
        mounted(){
            this.ScrollFrame();
        },
        beforeDestroy(){
            this.stopTick = true;
        },
        methods:{
            ScrollFrame(){
                let view = this;
                return;
                let labelFirst = $(this.$el).find('.label')[0];
                if(!labelFirst) return;
                let labelBox = labelFirst.getBoundingClientRect();
                let parentBox = labelFirst.parentNode.getBoundingClientRect();
                let margin = 5;
                let currentScroll = parseFloat(labelFirst.style.marginLeft.split('px')[0]);

                if(isNaN(currentScroll)) currentScroll = 0;

                currentScroll -= this.scrollSpeed / 100;
                labelFirst.style.marginLeft = currentScroll.toFixed(2) + 'px';

                let width = labelFirst.getBoundingClientRect().width + margin;
                requestAnimationFrame(function(){
                    if(labelBox.right < parentBox.left) {
                        let labelScrolled = view.textArray.shift();
                        view.textArray.push(labelScrolled);
                        requestAnimationFrame(function(){
                            labelFirst.style.marginLeft = 0;
                        });
                    }
                    if(!view.stopTick) view.ScrollFrame();
                });
            },
        },
    }
</script>

<style scoped>
    .header.ticker{
        height: 50px;
        background: var(--contrast-2);
        white-space: nowrap;
        overflow: hidden;
    }

    .header.ticker .label{
        white-space: nowrap;
        display: inline-flex;
        float: none;
        align-items: center;
        height: 100%;
        /*transition: all 0s linear 0s;*/
        color: var(--contrast-4);
        display: none;
    }
</style>